import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/AboutMe',
    name: 'AboutMe',
    component: () => import('../views/AboutMe.vue')
  },
  {
    path: '/blogsPage',
    name: 'blogs',
    component: () => import('../views/BlogPage.vue')
  },
  {
    path: '/blogDetail',
    name: 'blogDetail',
    component: () => import('../views/BlogDetail.vue')
  },
  {
    path: '/TestPage',
    name: 'TestPage',
    component: () => import('../views/TestPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If a saved position is available, use it
      return savedPosition;
    } else {
      // Scroll to the top of the page
      return { top: 0 };
    }
  }
})

export default router
