<template>
  <div>
    <v-app>
      <v-row v-if="$route.name != 'AboutMe'"
        class="py-0 my-0 fill-height hidden-sm-and-down"
        justify="center"
        style="
          background-color: white;
          z-index: 1;
          height: 35%;
          box-shadow: 0px 15px 10px -20px #a1a1a1;
        "
      >
        <v-col cols="2" class="text-left pa-0" align-self="center">
          <v-img
            height="40%"
            width="40%"
            src="./assets/icon/logoblack.png"
            alt="logo"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <v-row no-gutters class="px-4" justify="center">
            <v-col
              cols="6"
              sm="6"
              md="3"
              align-self="center"
              class="text-center"
              style="cursor: pointer"
              @click="$router.push('/')"
            >
              <a style="text-decoration: none" class="font1a">Home</a>
            </v-col>
            <v-col
            cols="6"
            sm="6"
            md="3"
            align-self="center"
            class="text-center"
            style="cursor: pointer"
            @click="$router.push('/AboutMe')"
          >
            <a style="text-decoration: none" class="font1a">About Me</a>
          </v-col>
            <v-col
              cols="6"
              sm="6"
              md="3"
              align-self="center"
              class="text-center"
              style="cursor: pointer"
            >
              <v-menu width="auto" location="bottom" open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn
                    color="transparent"
                    tile
                    flat
                    v-bind="props"
                    class="px-0"
                  >
                    <a style="text-decoration: none" class="font1a">Categories</a>
                  </v-btn>
                </template>
                <v-card class="pa-4">
                  <v-row
                    justify="center"
                    class="pa-4"
                    style="background-color: white"
                  >
                    <v-col cols="12" align-self="center">
                      <v-list>
                        <v-list-item v-for="(item, i) in categoryList" :key="i">
                          <v-list-item-title>
                            <v-btn variant="text" class="px-0 text-black">
                              <a
                                style="
                                  color: black !important;
                                  text-decoration: none;
                                  text-transform: uppercase;
                                "
                                class="font1"
                                @click="changeBlogId(item.categoryName)"
                                >{{ item.categoryName }}</a
                              >
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              sm="6"
              md="3"
              align-self="center"
              class="text-center"
              style="cursor: pointer"
              @click="$router.push('/blogsPage')"
            >
              <a style="text-decoration: none" class="font1a">Blogs</a>
            </v-col>
           
            </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="2"
          align-self="center"
          class="text-md-right pb-5"
        >
          <v-row no-gutters justify="end" class="px-0">
            <v-col cols="2" align-self="end">
                  <a :href="'https://www.instagram.com/ansonphililip/?hl=en'" target="_blank">
              <v-img
                height="20px"
                width="20px"
                src="./assets/instagram.png"
              ></v-img>
              </a>
            </v-col>
          
            <v-col cols="2">
                  <a :href="'https://www.facebook.com/anson.phililip'" target="_blank">
              <v-img
                height="20px"
                width="20px"
                src="./assets/facebook.png"
              ></v-img>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!---------------------------- HEADER-------------------------------->
      <!-- <AppHeader v-if="$route.name != 'home'" />
      <AppHeader2 /> -->
      <!----------------------------XS HEADER-------------------------------->
      <v-app-bar app color="white" class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img
          src="./assets/icon/logoblack.png"
          height="40"
          width="40"
          alt="logo"
        ></v-img>
      </v-app-bar>
      <!---------------------------------------------------------------------->
      <v-navigation-drawer v-model="drawer" app class="hidden-md-and-up">
        <v-list>
          <v-list-item @click="navigateTo('/')">
            <v-row justify="center">
              <v-col cols="3">
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
              </v-col>
              <v-col cols="9">
                <v-list-item-title>Home</v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item >
          <v-row justify="center">
              <v-col cols="3">
                <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
              </v-col>
              <v-col cols="9">
                <v-list-item-title  @click="toggleCategorySubmenu">Category
                  <v-icon v-if="categorySubmenuOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
                </v-list-item-title>
              </v-col>
            </v-row>
            <v-list-item-group v-if="categorySubmenuOpen">
            <v-list-item v-for="(item, i) in categoryList" :key="i">
              <v-btn
                variant="text"
                class="px-0 text-black"
                @click="navigateToCategory(item.categoryName)"
              >
                {{ item.categoryName }}
              </v-btn>
            </v-list-item>
          </v-list-item-group>
          </v-list-item>
          <!-- Add submenu items here -->
          <v-list-item @click="navigateTo('/blogsPage')">
            <v-row justify="center">
              <v-col cols="3">
            <v-list-item-icon>
              <v-icon>mdi-newspaper</v-icon>
            </v-list-item-icon>
              </v-col>
              <v-col cols="9">
            <v-list-item-title>Blogs</v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item @click="navigateTo('/AboutMe')">
            <v-row justify="center">
              <v-col cols="3">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
              </v-col>
              <v-col cols="9">
            <v-list-item-title>About</v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!------------------------------------------------------------------------>
      <v-main class="pa-0 ma-0">
        <router-view />
      </v-main>
      <!------------------------------FOOTER------------------------------------->
      <v-row
        style="background-color: black"
        justify="center"
        align="center"
        class="mt-2"
      >
        <v-col cols="12" sm="8" md="10">
          <v-row
            class="py-6"
            style="background-color: black"
            justify="center"
            align="center"
          >
            <v-col cols="12" md="3" class="pt-6" align-self="start">
              <v-img
                height="60%"
                width="60%"
                src="./assets/icon/logowhite.png"
                alt="logo"
              ></v-img>
            </v-col>
            <v-col cols="12" md="3" class="pt-9" align-self="start">
              <v-row justify="center">
                <v-col cols="12" class="text-center">
                  <span
                    style="color: white; border-top: 1px solid white"
                    class="pt-4 title7"
                    >RECENT POST</span
                  >
                </v-col>
              </v-row>
              <v-row
                justify="center"
                py-6
                v-for="(item, i) in FullBlogs"
                :key="i"
                style="cursor: pointer;"
                @click="changeBlogId2(item.heading)"
              >
                <v-col cols="4" v-if="item.photo">
                  <v-img
                    height="60px"
                    width="60px"
                    :src="mediaURL + item.photo"
                  ></v-img>
                </v-col>
                <v-col cols="8">
                  <span
                    v-if="item.heading"
                    class="title8 py-2"
                    style="color: white"
                    >{{ item.heading.substring(0, 200) }}</span
                  ><br />
                  <span
                    v-if="item.created_at"
                    class="title9 py-2"
                    style="color: #75827a"
                    >{{ formatDate(item.created_at) }}</span
                  >
                </v-col>
              </v-row>
            
            </v-col>
            <v-col cols="12" md="3" class="pt-9" align-self="start">
              <v-row justify="center">
                <v-col cols="12" class="white--text text-center">
                  <span
                    style="color: white; border-top: 1px solid white"
                    class="pt-4 title7"
                    >MY BLOGS</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-img
                    height="160px"
                    width="100%"
                    :src="mediaURL + newBlog.photo"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" class="white--text text-left">
                  <span
                    style="color: white;"
                    class="pt-4 font5"
                    >Contact: <br/> ansphilip@gmail.com</span
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="pt-9" align-self="start">
              <v-row justify="center">
                <v-col cols="12" class="white--text text-center">
                  <span
                    style="color: white; border-top: 1px solid white"
                    class="pt-4 title7"
                    >SUBSCRIBE</span
                  >
                </v-col>
              </v-row>
              <v-row justify="center" class="pl-6">
                <v-col cols="3">
                  <a :href="'https://www.instagram.com/ansonphililip/?hl=en'" target="_blank">
                  <v-img
                    height="90%"
                    width="90%"
                    src="./assets/icon/instawhite.png"
                  ></v-img>
                  </a>
                </v-col>
              
                <v-col cols="3">
                  <a :href="'https://www.facebook.com/anson.phililip'" target="_blank">
                  <v-img
                    height="90%"
                    width="90%"
                    src="./assets/icon/facebookwhite.png"
                  ></v-img>
                  </a>
                </v-col>
              
              </v-row>
            
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" align-self="center"  style="
                    font-family: dmsansregular;
                    font-size: 12px;
                    color: white;
                    font-weight: 500;
                  "  class="text-center">
                  <span>
                    ©  Spirit Of Anson  2023 - All rights reserved
                  </span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span>Designed & Developed by
                    <a style="
                    font-family: dmsansregular;
                    font-size: 12px; text-decoration: none;
                    color: white;
                    font-weight: 400;
                  "  :href="'https://leopardtechlabs.com/'" target="_blank">Leopard Tech Labs Pvt Ltd</a>
                    </span>
        </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import store from "./store";
import AOS from 'aos'
import 'aos/dist/aos.css'
export default {
  data() {
    return {
      nav: false,
      drawer: false,
      categorySubmenuOpen: false,

      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      sideNav: true,
      categoryList: [],
      newBlog: {},
      FullBlogs: [],
      FullBlogs1: [],

      MenuItem: [
        {
          title: "Home",
          route: "/",
          name: "Home",
        },
        {
          title: "About",
          route: "/AboutMe",
          name: "About",
        },
        {
          title: "Categories",
          route: "/",
          name: "Categories",
        
        },
        {
          title: "Blogs",
          route: "/blogsPage",
          name: "blogsPage",
        },
      ],
    };
  },
  mounted() {
    this.getCat();
    this.getBlogs();
    AOS.init()
  },
  methods: {
    navigateTo(route) {
      this.drawer = false; // Close the drawer when navigating
      this.$router.push(route);
    },
    toggleCategorySubmenu() {
      this.categorySubmenuOpen = !this.categorySubmenuOpen;
    },
    navigateToCategory(categoryName) {
      console.log("categoryName=",categoryName)
      this.categorySubmenuOpen = false; 
      var name = categoryName
      this.changeBlogId(name)
    },
    changeBlogId(name) {
      // localStorage.setItem("blogId", id);
      this.$router.push("/blogsPage?name=" + name);
    },
    changeBlogId2(name) {
      // localStorage.setItem("blogId", id);
      this.$router.push("/blogDetail?name=" + name);
    },
    logout() {
      store.commit("logout");
    },
    getCat() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/view/allCategory",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.categoryList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            location.reload();
          }
        })
        .catch((err) => {
          // store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getBlogs() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/allBlogs",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // catid: this.categoryId,
          limit: "4",
          page: "1",
        },
      })
        .then((response) => {
          this.FullBlogs1 = response.data.data;
          this.newBlog = this.FullBlogs1[0];
          this.FullBlogs = this.FullBlogs1;
          this.FullBlogs.shift();
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
