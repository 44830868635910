import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag";
import { loadFonts } from './plugins/webfontloader'

import axios from 'axios';
import './assets/style.css';
import './assets/font.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueElementLoading from 'vue-element-loading';
loadFonts()

const app = createApp(App);
app.component('VueElementLoading', VueElementLoading);

// axios.defaults.baseURL = 'http://192.168.52.54:5555';
// app.config.globalProperties.baseURL = "http://192.168.52.54:5555";
// app.config.globalProperties.mediaURL = "http://192.168.52.54:5555/file/get/";

// axios.defaults.baseURL = 'http://65.2.39.24/';
// app.config.globalProperties.baseURL = "http://65.2.39.24/"
// app.config.globalProperties.mediaURL = "http://65.2.39.24/"
axios.defaults.baseURL = 'https://api.spiritofanson.com/';
app.config.globalProperties.baseURL = "https://api.spiritofanson.com/"
app.config.globalProperties.mediaURL = "https://api.spiritofanson.com/file/get/"

app.config.globalProperties.$axios = axios;

app
  .use(AOS)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueGtag, {
    config: { id: "G-L2EXLY2S0B"}
  })
  .mount('#app');


