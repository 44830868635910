<template>
  <div>
    <vue-element-loading :active="appLoading"  :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f" spinner="bar-fade-scale" />

    <v-row no-gutters>
      <v-col cols="12">
       <v-card elevation="0">
        <v-img cover
          height="100%"
          width="100%"
          :src="mediaURL + bannerImage.photo"
          class="align-end text-white"
        ></v-img>
       </v-card>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      style="position: relative; margin-top: -10%"
      justify="center"
    >
      <v-col cols="12" sm="6" md="11" class="px-3">
        <!----------------------------------------BANNER---------------------------------------------->
        <v-row justify="center">
          <v-col
            cols="12"
            md="3"
            class="px-4 mx-2 pa-md-0"
            v-for="(item, i) in categoryList"
            :key="i"
          >
            <v-card v-if="item" @click="this.$router.push('/blogsPage?name='+item.categoryName)"
            data-aos="zoom-in" data-aos-duration="1000">
              <v-img
                v-if="item.photo"
                height="300"
                :src="mediaURL + item.photo"
                cover
                class="align-end text-white"
              >
                <v-row no-gutters justify="center" class="py-2">
                  <v-col
                    cols="11"
                    class="py-2 text-center font2"
                    align-self="center"
                    v-if="item.categoryName"
                  >
                    {{ item.categoryName }}
                  </v-col>
                  <v-col
                    cols="11"
                    class="py-2 text-center font3"
                    align-self="center"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </v-col>
                  <v-col class="py-2 text-center title1" cols="11"
                    >Read More</v-col
                  >
                </v-row>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
        <!----------------------------------------BLOGS---------------------------------------------->
        <v-row no-gutters justify="center" class="pt-4">
          <v-col cols="10" sm="8" md="10">
            <v-row no-gutters justify="center" class="row-main pb-4 pt-10">
              <!----------------------------------------LEFT COL--------------------------------------------->
              <v-col cols="12" md="9" class="list">
                <v-row no-gutters  class="static-row-first" v-if="isToday==true" data-aos="fade-up" data-aos-duration="2000">
                  <v-col cols="12" md="6" class="pr-2" v-if="TodaysFirstThought"  @click="
                      $router.push('/blogDetail?name=' + TodaysFirstThought.heading)
                    " style="cursor:pointer;">
                    <v-img
                      cover
                      height="500px"
                      width="100%"
                      :src="mediaURL + TodaysFirstThought.photo"
                    ></v-img>
                  </v-col>
                  <v-col
                    @click="
                      $router.push('/blogDetail?name=' + TodaysFirstThought.heading)
                    "
                    cols="12"
                    md="6"
                    style="
                      background-color: #26262d;
                      
                      cursor: pointer;
                    "
                    class="pa-1 text-center"
                  >
                    <v-card
                      class="pa-6"
                    color="#26262d" elevation="0"
                    >
                      <v-row justify="center" v-if="TodaysFirstThought">
                        <v-col
                        cols="11" v-if="TodaysFirstThought"
                        class="text-center font2b" style="color: red !important;"
                        >{{ TodaysFirstThought.categoryId.categoryName}}</v-col
                      >
                        <v-col
                          cols="11" v-if="TodaysFirstThought"
                          class="pb-4 text-center font3 text-white"
                          >{{ TodaysFirstThought.heading }}</v-col
                        >
                        <v-col cols="4"
                          ><v-divider
                            style="background-color:#ffffff!important;"
                            thickness="3"
                          ></v-divider
                        ></v-col>
                        <v-col
                          v-if="TodaysFirstThought"
                          cols="12"
                          class="py-4 text-center font5 text-white"
                          ><span
                            v-html="TodaysFirstThought.description.substring(0, 300)"
                          ></span
                          >...Read More</v-col
                        >
                      </v-row>
                    </v-card>
                  </v-col>
</v-row>
                <v-row no-gutters class="static-row-first" v-else>
                  <v-col cols="12" md="6" class="pr-2" v-if="firstBlog.blog"  @click="
                      $router.push('/blogDetail?name=' + firstBlog.blog.heading)
                    " style="cursor:pointer;">
                    <v-img
                      cover
                      height="500px"
                      width="100%"
                      :src="mediaURL + firstBlog.blog.photo"
                    >
                     <!-- <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="blue lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template> -->
                  </v-img>
                  </v-col>
                  <v-col
                    @click="
                      $router.push('/blogDetail?name=' + firstBlog.blog.heading)
                    "
                    cols="12"
                    md="6"
                    style="
                      background-color: #26262d;
                      
                      cursor: pointer;
                    "
                    class="pa-1 text-center"
                  >
                    <v-card
                      class="pa-6"
                    color="#26262d" elevation="0"
                    >
                      <v-row justify="center" v-if="firstBlog">
                        <!-- <v-col
                          cols="11"
                          class="py-4 text-center font2 text-white"
                          v-if="firstBlog.categoryName"
                          >{{ firstBlog.categoryName }}</v-col
                        > -->
                        <v-col
                          cols="11" v-if="firstBlog.blog"
                          class="pb-4 text-center font3 text-white"
                          >{{ firstBlog.blog.heading }}</v-col
                        >
                        <v-col cols="4"
                          ><v-divider
                            style="background-color:#ffffff!important;"
                            thickness="3"
                          ></v-divider
                        ></v-col>
                        <v-col
                          v-if="firstBlog.blog"
                          cols="12"
                          class="py-4 text-center font5 text-white"
                          ><span
                            v-html="firstBlog.blog.description.substring(0, 300)"
                          ></span
                          >...Read More</v-col
                        >
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
          <v-col
            cols="12"
            md="3"
            class="px-4 mx-2 pa-md-0"
            v-for="(item, i) in myArray"
            :key="i"
            style="cursor: pointer"   @click="
                          $router.push('/blogDetail?name=' + item.blog.heading)"
          >
          <v-row>
            <v-col cols="12" class="py-1">
              <v-card v-if="item"  data-aos="zoom-in" data-aos-duration="1000">
              <v-img
                v-if="item.blog.photo"
                height="200"
                width="250"
                :src="mediaURL + item.blog.photo"
                cover
                class="align-end text-white"
              >
              </v-img>
              </v-card>
            </v-col>
         
              <v-col
                        cols="12"
                        class="text-center font2b py-1"
                        v-if="item.categoryName"
                      >
                        <span style="color: #f54233">{{
                          item.categoryName
                        }}</span>
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center font3b py-1"
                        v-if="item.blog"
                      >
                        <span>{{ item.blog.heading }}</span>
                      </v-col>
              </v-row>
       
          
        </v-col>
      </v-row>
               <v-row class="py-4">
                  <v-divider thickness="1" color="#525252"></v-divider>
                </v-row>
                <v-row
                  class="py-4"
                  justify="center"
                  style="cursor: pointer"
                  v-if="newBlog"
                  @click="$router.push('/blogDetail?name=' + newBlog.heading)"
                >
                  <v-col
                    cols="8"
                    class="text-center font2b pt-4 pb-0"
                    v-if="newBlog.categoryId"
                    ><span style="color: red">{{
                      newBlog.categoryId.categoryName
                    }}</span></v-col
                  >
                  <v-col
                    cols="8"
                    class="text-center py-4 font6 py-2"
                    v-if="newBlog.heading"
                    >{{ newBlog.heading }}</v-col
                  >
                  <v-col cols="12" class="text-py-4">
                    <v-img
                      height="100%"
                      width="100%"
                      :src="mediaURL + newBlog.photo"
                    ></v-img>
                  </v-col>
                  <!-- <v-col cols="6" class="text-py-4 ">
                <v-card class="pa-4">
                  <span v-html="newBlog.description.substring(0, 400)" style="word-wrap: break-word;"></span><v-chip  @click="$router.push('/blogDetail?name='+item._id)">Read more</v-chip>
                </v-card>
</v-col> -->
                </v-row>
                <v-row justify="center">
                  <v-col
                  v-for="(column, columnIndex) in columns"
      :key="columnIndex"
      cols="12"
      md="6"
      style="height: auto; display: flex; flex-direction: column;"
                  >
                    <v-row
                    v-for="(item, rowIndex) in column" :key="rowIndex"
                      class="py-4"
                      style="cursor: pointer"
                      justify="center"
                      @click="$router.push('/blogDetail?name=' + item.heading)"
                    >
                      <v-col cols="12" class="text-py-4" >
                        <v-img
                          v-if="item.photo"
                          height="100%"
                          width="100%"
                          :src="mediaURL + item.photo"
                        ></v-img>
                      </v-col>
                      <v-col cols="8" class="text-center font2b"
                        ><span style="color: red">{{
                          item.categoryId.categoryName
                        }}</span></v-col
                      >
                      <v-col
                        cols="8"
                        class="text-center py-4 font6"
                        style="word-wrap: break-word"
                        >{{ item.heading }}</v-col
                      >

                      <v-col cols="12" class="text-center title3"
                        ><span
                          v-html="item.description.substring(0, 400)"
                          style="word-wrap: break-word"
                        ></span
                        >
                        </v-col>
                        <v-col cols="12" class="text-center title3 pt-2"
                        >
                        <v-btn style="background-color: black;border-radius: 0;" variant="text"
                          @click="$router.push('/blogDetail?name=' + item.heading)"
                          ><span style="color:white">Read more</span></v-btn
                        ></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center" class="static-row-last">
                  <v-col cols="12" md="3">
                    <v-chip
                      @click="$router.push('/blogsPage')"
                      size="large"
                      variant="outlined"
                      class="title1 text-black"
                      style="font-size: 11px; cursor: pointer"
                    >
                      VIEW MORE
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
              <!----------------------------------------RIGHT COL-------------------------------------------->
              <v-col cols="12" md="3" class="pl-6">
                <v-row
                  justify="center"
                  align="center"
                  style="position: sticky; top: 0"
                >
                
                  <v-col cols="10" class="text-center pt-4 title3"
                    >CATEGORIES</v-col
                  >
                  <v-col cols="7" class="text-center pt-0"
                    ><v-divider color="black"></v-divider
                  ></v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" class="pr-2"  v-for="(item, i) in fullcategoryList"
            :key="i" style="cursor:pointer;">
                        <v-img
                          height="100%"
                          width="100%"
                          :src="mediaURL + item.photo"
                          @click="$router.push('/blogsPage?name='+item.categoryName)"
                        >
                        <v-row no-gutters justify="center" class="py-2 fill-height">
                  <v-col
                    cols="11"
                    class="py-2 text-center font3"
                    align-self="center"
                    v-if="item.categoryName"
                  >
                    {{ item.categoryName }}
                  </v-col>
                </v-row>
                      </v-img>
                   <!-- <v-row justify="center">
                    <v-col cols="9" class="py-2 text-center">
                        <v-divider style="color: grey;"></v-divider>
                      </v-col>
                   </v-row> -->
                      </v-col>
                     
                      <!-- <v-col cols="6" class="pl-2">
                        <v-img
                          height="100%;"
                          width="100%"
                          src="https://lemars.dexignzone.com/xhtml/images/gallery/widget-gallery/pic2.jpg"
                        ></v-img>
                      </v-col>
                      <v-col cols="6" class="pr-2">
                        <v-img
                          height="100%;"
                          width="100%"
                          src="https://lemars.dexignzone.com/xhtml/images/gallery/widget-gallery/pic3.jpg"
                        ></v-img>
                      </v-col>
                      <v-col cols="6" class="pl-2">
                        <v-img
                          height="100%;"
                          width="100%"
                          src="https://lemars.dexignzone.com/xhtml/images/gallery/widget-gallery/pic4.jpg"
                        ></v-img>
                      </v-col> -->
                    </v-row>
                  </v-col>
              
                  <v-col cols="10" class="text-center pt-0 title3"
                    >SOCIAL</v-col
                  >
                  <v-col cols="7" class="text-center pt-0"
                    ><v-divider color="black"></v-divider
                  ></v-col>
                  <v-col cols="12" class="pt-0 pb-2 text-center">
                    <v-row no-gutters justify="center" class="py-0">
                      <v-col cols="2" align-self="center" class="text-center">
                  <a :href="'https://www.instagram.com/ansonphililip/?hl=en'" target="_blank">
                        <v-img
                          height="20px"
                          width="20px"
                          src="./../assets/instagram.png"
                        ></v-img>
                        </a>
                      </v-col>
                      <!-- <v-col cols="2" align-self="center" class="text-center">
                        <v-img
                          height="20px"
                          width="20px"
                          src="./../assets/twitter.png"
                        ></v-img>
                      </v-col>
                      <v-col cols="2" align-self="center" class="text-center">
                        <v-img
                          height="20px"
                          width="20px"
                          src="./../assets/pinterest.png"
                        ></v-img>
                      </v-col> -->
                      <v-col cols="2" align-self="center" class="text-center">
                  <a :href="'https://www.facebook.com/anson.phililip'" target="_blank">
                        <v-img
                          height="20px"
                          width="20px"
                          src="./../assets/facebook.png"
                        ></v-img>
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!------------------------------------------------------------------------------------------->
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import ImageLoader from './ImageLoader.vue';
import axios from "axios";
export default {
  // components: {
  //   ImageLoader,
  // },
  data() {
    return {
      nav: false,
      showsnackbar: false,
      timeout: 5000,
      msg: null,
      columns: [],
      bannerImage: {},
      categoryList: [],
      CatBlogList: [],
      fullcategoryList:[],
      FullBlogs: [],
      FullBlogs1: [],
      firstBlog: {},
      newBlog: {},
      myArray: [],
      appLoading:false,
      TodaysThoughtFull:[],
      TodaysFirstThought:{},
      isToday:false,
    };
  },
  mounted() {
    this.getData();
    this.getCat();
    this.getFullCat();
    this.getFirstBlogs();
    this.getBlogs();
    this.getTodayThought();
  },
  computed: {
    itemsPerColumn() {
      // Calculate the number of items per column (half of the array length)
      return Math.ceil(this.FullBlogs.length / 2);
    },
  },
   watch: {
    FullBlogs: {
      handler(newVal) {
        // Split the FullBlogs array into columns
        this.columns = [];
        for (let i = 0; i < newVal.length; i += this.itemsPerColumn) {
          this.columns.push(newVal.slice(i, i + this.itemsPerColumn));
        }
      },
      immediate: true,
    },
  },
  methods: {
    
    getData() {
        this.appLoading = true;
      axios({
        method: "get",
        url: "/view/allphotos",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
            this.appLoading = false;
          if (response.data.status == true) {
            this.bannerImage = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            location.reload();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getCat() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/view/allCategory",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: "1",
          limit: "3",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.categoryList = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            location.reload();
          }
        })
        .catch((err) => {
          // store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getFullCat() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/view/allCategory",
        headers: {
          token: localStorage.getItem("token"),
        },
       
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            let fullcategoryList1 = response.data.data;
            this.fullcategoryList = fullcategoryList1.reverse();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            location.reload();
          }
        })
        .catch((err) => {
          // store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getFirstBlogs() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/category/blogs",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // catid: this.categoryId,
          limit: "4",
          page: "1",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.CatBlogList = response.data.data;
          this.firstBlog = this.CatBlogList[0];
          this.myArray = this.CatBlogList;
          this.myArray.shift();
          console.log(this.myArray);
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getBlogs() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/exclude/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // catid: this.categoryId,
          limit: "5",
          page: "1",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.FullBlogs1 = response.data.data;
          this.newBlog = this.FullBlogs1[0];
          this.FullBlogs = this.FullBlogs1;
          this.FullBlogs.shift();
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getTodayThought() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/allBlogs",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          categoryName: "Today's Thought",
          limit: "5",
          page: "1",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.TodaysThoughtFull = response.data.data;
          if(this.TodaysThoughtFull.length>0){
          this.TodaysFirstThought = this.TodaysThoughtFull[0];
          this.isToday=true
          }
          else{
            this.isToday=false;
          }
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.image-loader {
  position: relative;
}

/* Style for the loader */
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Style for the animated spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db; /* Change the spinner color as needed */
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* animation */
.kenburns-top{-webkit-animation:kenburns-top 15s ease-out both;animation:kenburns-top 15s ease-out both}
@-webkit-keyframes kenburns-top{0%{-webkit-transform:scale(1) translateY(0);transform:scale(1) translateY(0);-webkit-transform-origin:50% 16%;transform-origin:50% 16%}100%{-webkit-transform:scale(1.25) translateY(-15px);transform:scale(1.25) translateY(-15px);-webkit-transform-origin:top;transform-origin:top}}@keyframes kenburns-top{0%{-webkit-transform:scale(1) translateY(0);transform:scale(1) translateY(0);-webkit-transform-origin:50% 16%;transform-origin:50% 16%}100%{-webkit-transform:scale(1.25) translateY(-15px);transform:scale(1.25) translateY(-15px);-webkit-transform-origin:top;transform-origin:top}}
</style>
